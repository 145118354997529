//Tables
.table-hold {
  background: $body-light ;
  padding: $padd20;
}

thead {
  background-color: #D6D6D6;
  color: $text-color;
}

thead h3{
  background-color: #D6D6D6;
  color: $text-color;
}

table.dataTable tbody tr {
  background-color: $body-light;
}

.table-hold h1 {
  margin-top: $spacer-none;
  margin-bottom: $padd-md;
  font-weight: 400;
}

// Tabber
.table-style-1 {
  margin: $spacer-none $spacer-none !important;
  padding: $padd-md $spacer-none ;
}

.nav-tabs > li > a {
  font-size: $h2;
  color:$main-p;
}

.nav-tabs > li.active > a {
  color: $secondary-color;
}

.tab-content {
  margin-top: 20px;
  margin-bottom: 20px;
}

.table-hold h1 span {
  font-weight: 800;
}

.table-hold h3 {
  margin-top: $spacer-none;
  margin-bottom: 20px;
  font-weight: 400;
}

.table-hold h3 span {
  font-weight: 800;
}

.table-hold h2 span {
  font-weight: 800;
}

.table-bordered {
  margin-top:15px;
}

.ico-hold .fa{
  color: $btn-brand;
}

.icon-dropdown {
  margin-right: 10px;
  width: 10px;
  color: $primary-color;
}

.icon-dropdown-red {
  margin-right: 10px;
  width: 10px;
  color: $delete-color;
}

.red-li {
  color: $delete-color !important;
}

a#delete-btn {
  color: $delete-color !important;
}

.icon-cogholder {
  color: $primary-color;
  font-size: $ico-md !important;
}

.ico-hold span {
  position: relative;
  top: -3px;
  left: 3px;
}

.ico-orange {
  color: $brand-warning
}

.ico-orange .fa{
  color: $brand-warning;
}

i.fa.fa-trash.icon-trash {
  color: $brand-danger;
  font-size: 22px;
}

.winning-bid {
  font-weight: $weight-bold;
}

//Modal
.modal-content {
  border-radius: $radius-one;
}

.modal-backdrop {
  background-color: $main-p;
  opacity:0.7;
}

.modal-footer {
  background-color: $navbar-default-bg;
}

.modal-body {
  padding: $padd-md $padd15;
  background-color: $body-bg ;
}

.g-border {
  border-top: $border-thick;
  border-color: $border-dark;
  margin-bottom: $padd-md;
}

// Separators
.right-separator {
  border-left: $border-main $border-light;
}

//Spacer
.spacer-md-right {
  padding-left:$padding-left;
}

.spacer-md-left {
  padding-right:$padding-right;
}

.spacer-lg {
  padding: $padd-lg ;
}

.btn-spacer-lg {
  margin: $padd-lg $spacer-none $spacer-none ;
}

.spacer-top {
  margin-top: $padd-md;
}

.btn-row-offset {
  margin-top: 23px;
}

.nobutton {
  background: none;
  padding: 0px;
  border: none;
}

.boxHeader {
  height: 35px;
  line-height: 18px;
  font-size: 14px;
  background-color: #efefef;
  padding: 10px;
}

.boxHeader span {
  color: $primary-color;
  font-weight: bold;
}

a.list-action-link {
  display: block;
  color: #666666;
  border-bottom: 1px dotted #ccc;
  padding-bottom: 5px;
  margin-bottom: 5px;
  font-size: 13px;
}

a.list-action-link:hover {
  color: #000;
  text-decoration: none;
}

.modal-table {
  margin-top: 15px;
}

table.modal-table {
  border: 1px dotted #ccc;
}

.modal-table td, th {
  padding: 4px;
}

.modal-table tr {
  height: 30px;
  border-bottom: 1px dotted #ccc;
}

.color-green {
  color: lightgreen;
}

.bid-switcher {
  margin-bottom: 15px;
  font-size: 12px;
  color: #999;
}

.center-butt {
  width: 20%;
  margin-left: auto;
  margin-right: auto;
  display: list-item;
}

.center-inline {
  width: auto;
  margin-left: 10px;
}
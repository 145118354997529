// Fonts
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700);
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);

// Datatables CDN
@import url(https://cdn.datatables.net/1.10.13/css/jquery.dataTables.min.css);

//Date Timepicker
@import url(https://cdnjs.cloudflare.com/ajax/libs/bootstrap-datetimepicker/4.17.47/css/bootstrap-datetimepicker.min.css);

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Main Variables
@import "variables";

//SITCCS - Simplified ITCSS [by Mustard Agency]
@import "settings";
@import "generic";
@import "elements";
@import "trumps";
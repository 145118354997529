// Main
body {
  background-color: $body-bg;
}

h1, .h1 {
  font-size: $h1;
}

h2, .h2 {
  font-size: $h2;
}

h3, .h3 {
  font-size: $h3;
}

h1  {
  font-weight: 400;
}

h1 span {
  font-weight: 800;
}

p{
  color: $main-p;
  font-size: $font-main ;
  line-height: $line-height-medium ;
}

.btn-link {
  color: $link-default;
}

.a-color {
  color: $link-default;
}

.a-color:hover {
  color: $hoverbtn;
}

.btn-link:hover{
  color: $link-default;
}

.btn-md {
  width: auto !important;
}

.highlight {
  color: $btn-brand;
}

//Buttons

//Mixin
%btn-def {
  color: $body-light;
  background-color: $additional-color;
  border-color: $additional-color;
  font-weight: $weight-bold;
  padding: $default-btn-padd;
}
//Mixin

.btn-default {
  color: $secondary-color;
  background-color: $additional-color;
  border-color: $additional-color;
  font-weight: $weight-bold;
  padding: 5px 20px;
}

.btn-primary {
   border-color:$btn-brand ;
   background-color:$btn-brand ;
   text-transform: $uppercase;
   padding: $default-btn-padd;

}

.btn-primary:focus {
  background-color:$btn-brand ;
  border-color:$btn-brand ;
}

.btn-hidden {
  background-color: transparent;
  display: block;
  padding: 3px 20px;
  line-height: 1.42857143;
  white-space: nowrap;
}

.btn-primary:hover {
  background-color: $hoverbtn;
  border-color: $hoverbtn;
}

.btn-warning{
  width: 100%;
  text-transform: uppercase;
  padding: 7px 10px;
  border-color:$brand-warning  !important;
  background-color:$brand-warning  !important;
}

.btn-spacer {
  margin-bottom: $padd15;
}

.btn-hidden:focus, .btn-hidden:hover {
  text-decoration: none;
  color: #262626;
  background-color: #f5f5f5;
}

.btn-danger-1 {
  @extend %btn-def;
  background-color:$brand-danger !important;
  text-decoration: $none;
  font-weight: normal;
  text-transform: $uppercase;
  margin-left: 10px;
}

.btn-danger-1:hover {
  color: #fff !important;
}

//.form-control {
//  height: 38px;
//  padding: 18px 10px;
//}

.form-row {
  clear: both;
}

.row-spacer {
  margin-top: $padd15;
}

.container-fluid {
  padding-left: 25px;
  padding-right: 25px;
}


@media only screen and (max-width: 1440px){
  .btn {
    font-size: 13px;
  }

  table.perf-table thead th {
    padding: 10px !important;
    border-bottom: 1px solid #111;
    font-size: 12px !important;
  }

  table.perf-table thead th.sorting:after {
    content: "\f0dc";
    color: #333;
    font-size: 0.8em;
    padding-top: 0px;
    margin: -6px 0px;
  }

}

@media only screen and (max-width: 1280px){

  .btn {
    font-size: 12px;
  }

  .table-hold.g-border {
      padding: 20px 15px;
  }

}
// Menu / Header
.navbar-default {
  background-color: $navbar-default-bg;
  border-color: $navbar-default-bg;
  padding-top: $padd15;
  border:$spacer-none;
}

.navbar-default .navbar-nav > .active > a {
  border-radius: 8px 8px 0 0;
}

.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
  color: $primary-color-light;
}

// Vertical Settings
#app {
  position:absolute;
  width:$tf-width ;
  height:$tf-height;
  display: $tf-display;
}

.tabholds {
  width:$tf-width ;
  height:$tf-height;
  display: $tf-display;
  vertical-align: middle;

}

.tabc {
  width:$tf-width ;
  height:$tf-height;
  display: $tc-display;
  vertical-align: middle;
}

.inner-block {
  display: $in-block;
  width: $tf-width;
}

// Form Groups
.form-group .col-md-12{
  padding: 0;
}

.form-horizontal .form-group {
  margin-left: 0;
  margin-right: 0;
}

.form-group .btn-primary:hover {
  background-color: $hoverbtn;
  border-color: $hoverbtn;
}

.form-group {
  display: $frm-flex;
}

.form-group .col-md-12{
  padding: 0;
}

.form-horizontal .form-group {
  margin-left: 0;
  margin-right: 0;
}

.form-group .btn-primary:hover {
  background-color: $hoverbtn;
  border-color: $hoverbtn;
}

.form-group {
  display: $frm-flex;

}
.flash-message-holder {
  height: 30px;
  line-height: 30px;
}

.flash-message-holder p {
  font-size: 13px;
  text-align: center;
}

.flash-red, .flash-red p {
  background-color: $brand-danger;
  color: $primary-color-light;
}

.flash-green, .flash-green p {
  background-color: $primary-color;
  color: #29363f;
}

// Parsley
.parsley-errors-list {
  color: $darker-color;
  font-size: 11px;
  list-style-type: none;
  padding: 6px;
  margin-bottom: $spacer-none;
}

input.parsley-error, select.parsley-error, textarea.parsley-error {
  color: #B94A48;
  background-color: #F2DEDE;
  border: 1px solid #EED3D7;
}

input.parsley-success, select.parsley-success, textarea.parsley-success {
  color: #666666;
  background-color:rgba(103,221,162,0.2);
  border: 1px solid rgba(103,221,162,0.7);
}

.bs-callout-warning {
  color: #B94A48;
  background-color: #F2DEDE;
  border: 1px solid #EED3D7;
}

.bs-callout-warning p {
  color: #B94A48;
}

.bs-callout-info {
  color: $darker-color;
  background-color:rgba(103,221,162,0.2);
  border: 1px solid rgba(103,221,162,0.7);
}

.bs-callout-info p {
  color: $darker-color;
}

.stat-hold {
  background-color: $primary-color-light;
  text-align: center;
  padding: $padd-md $padd15;
  display: inline-block;
  width: 100%;
}

.stat-hold h4{
  color: $primary-color;
  font-size:18px;
  }

.square-stat h5 {
  font-size: 15px;
  text-align: center;
  font-weight: normal;
}

.summer-hold {
  margin:20px 0;
}

.summery-text {
  font-size: 14px;
}

.summer-hold div{
  clear:both;
  padding: 5px 0px;
}

.total-red {
  color: $brand-danger;
  font-weight: 500;
}

.sub-table {
  padding: 10px !important;
}

.seller-parcel-sub {

}

.table-condensed > tbody > tr > td {
  padding: 7px;
}

.accepted-parcel-sub {
  background-color: $brand-danger;
  color:#fff;
}

.rejected-parcel-sub {
  color:#fff;
  background-color: #67DDA2;
}

.bg-light {
  background: #fff;
  padding: 20px 15px;
  display: inline-block;
  width: 100%;
}

.bg-light h1{
  margin-top:0px;
}

.bg-light .icon-cogholder {
  float: left;
  background-color: #67DDA2;
  height: 35px;
  width: 35px;
  color: #fff;
  text-align: center;
  padding: 7px 0px;
  border-radius: 50%;
  margin-top: -5px;
  margin-right: 10px;
}

.custom-message{
  padding: $padd15 !important;
}

.margin-none {
  margin-bottom: 0;
}

hr {
  border-top: dashed 1px #000;
}


.add-padd .form-group .col-md-12 {
  padding: 0 15px;
}

.input-group-addon, .input-group-btn {
  vertical-align: bottom;
}

.input-group .form-control {
  height: 32px;
  padding: 15px 10px;
}

.table-spacer {
  margin-left: 30px;
}

.table-link a, a.table-link, .table-link {
  color: #9EC4DE;
  font-size: 13px;
}

.divTable{
  display: table;
  width: 100%;
}
.divTableRow {
  display: table-row;
}
.divTableHeading {
  background-color: #ccc;
  display: table-header-group;
}
.divTableCell, .divTableHead {
  border: 1px solid #ccc;
  display: table-cell;
  padding: 3px 10px;
}
.divTableHeading {
  background-color: #666;
  display: table-header-group;
  font-weight: bold;
}
.divTableFoot {
  background-color: #EEE;
  display: table-footer-group;
  font-weight: bold;
}
.divTableBody {
  display: table-row-group;
}

.ico-red {
  color: $brand-danger;
  font-weight: 500;
}

.ico-green {
  color: rgba(103,221,162,0.7);
  font-weight: 500;
}

#mybids .input-group .form-control {
  height: 36px !important;
  padding: 15px 10px;
}
// Body
$body-bg: #efefef;
$body-light :#fff;

//Main
$main-p:#999999 ;
$font-main: 16px;
$line-height-medium: 29px;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands

$primary-color-light: #efefef;
$primary-color: #9EC4DE;
$secondary-color: #333333;
$additional-color: #D6D6D6;
$delete-color: #B94A48;

//Special - Dark Grey
$darker-color: #666666;

$brand-primary: #3097D1;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
//$brand-warning: #cbb956;
$brand-warning: #F99728;
$brand-danger: #B94A48;

// Typography
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$font-family-sans-serif: "Source Sans Pro", sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: #999999;
$weight-bold: bold;
$uppercase: uppercase;

//Font Sizes
$h1: 22px ;
$h2: 18px ;
$h3: 16px ;

$ico-sm: 18px;
$ico-md: 22px;
$ico-lg: 28px;

// Navbar
$navbar-default-bg: #3B3B3A;

// Buttons
$btn-default-color: $text-color;
$btn-brand :#9EC4DE ;

$width-full:100%;
$width-half:50%;

$default-btn-padd: 7px 10px;


// Borders Custom
$border-light: #D0D2D3;
$border-dark: #9EC4DE;

$border: 1px solid;
$border-main : 1px solid;
$border-thick: 6px solid;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;

//Vertical Aligns
$tf-height: 100%;
$tf-width: 100%;
$tf-display: table;
$tc-display: table-cell;
$in-block: inline-block;

// A & Href
$link-default: #999999;
$hoverbtn: #97B6D0;

//Padding
$padding-right: 50px;
$padding-left: 50px;
$padd15: 15px;
$padd20: 20px;
$padd-md: 30px;
$padd-lg: 50px;

// Forms
$frm-flex: flex;

// Responsive & Resets
$img-reponsive: 100%;
$spacer-none: 0;
$radius-one: 0;
$auto: auto;

//Misc
$none : none;
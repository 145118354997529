@import "variables";

//Log Sizing
.navbar-brand>img {
//  width: 45px;
//  margin-top: -5px;
  width: 200px;
  margin-top: -15px;
}

table.dataTable tbody tr {
  background-color: #fff !important;
}

table.dataTable thead th, table.dataTable thead td {
  border-bottom:none;
}

table.dataTable.no-footer {
  border-bottom: 1px solid #ddd;
}

thead {
  background-color: #D6D6D6;
  color: #333;

}

.dataTables_length label{
  padding: 10px 0;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current {
  color: #fff !important;
  border: 1px solid #979797;
  // background-color: #999999;
  background:#999999;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: #fff !important;
  border: 1px solid #979797;
  background: #999999;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  margin-left: 0px;
  text-align: center;
  border-radius: 0px;
  background: #CCCCCC;
  color: #fff;
  border-right: #999999;
}

#DataTables_Table_0_wrapper .dataTables_paginate .paginate_button .current:hover {
  color: #fff !important;
  border: 1px solid #979797;
  background: #999999;
}

// .dataTables_filter {
//   display: none;
// }

table.dataTable thead th {
  position: relative;
  background-image: none !important;
}

table.dataTable thead th.sorting:after,
table.dataTable thead th.sorting_asc:after,
table.dataTable thead th.sorting_desc:after {
  position: absolute;
  top: 12px;
  right: 8px;
  display: block;
  font-family: FontAwesome;
}

table.dataTable thead th.sorting:after {
  content: "\f0dc";
  color: #333;
  font-size: 0.8em;
  padding-top: 0.12em;
}

table.dataTable thead th.sorting_asc:after {
  content: "\f0de";
}

table.dataTable thead th.sorting_desc:after {
  content: "\f0dd";
}

table.dataTable tbody .total-green {
  font-weight: bold;
  background-color: $primary-color;
}

.total-green td.sorting_1 {
  background: $primary-color;
}


.alert {
  margin-bottom: 0 !important;
}

label {
  display: inline-block;
  margin-bottom: 4px;
  font-size: 12px;
  color: $darker-color;
}


.bootstrap-tagsinput {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  padding: 10px 6px;
  color: #333333;
  vertical-align: middle;
  border-radius: 4px;
  max-width: 100%;
  line-height: 22px;
  cursor: text;
  height: 100px;
}

.label {
  display: inline;
  padding: .2em .6em .3em;
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  color: #333333;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
}

.bootstrap-tagsinput .tag {
  margin-right: 2px;
  color: #333333;
}

.label-info {
  background-color: #E6E6E6;
}

.bootstrap-tagsinput input:focus {
  border: none;
  box-shadow: none;
}

.bootstrap-tagsinput input {
  border: none;
  box-shadow: none;
  outline: none;
  background-color: transparent;
  padding: 0 6px;
  margin: 0;
  width: auto;
  max-width: inherit;
}

.bootstrap-tagsinput .tag [data-role="remove"] {
  margin-left: 8px;
  cursor: pointer;
}

.bootstrap-tagsinput .tag [data-role="remove"]:after {
  content: "x";
  padding: 0px 2px;
}

.lock {
  position: relative;
}
.lock::after {
  content: "\f02";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  color: #000;
  font-size: 18px;
  padding-right: 0.5em;
  position: absolute;
  top: 10px;
  left: 0;
}

.fa-lock {
  float: left;
  position: absolute;
  top: 33px;
  right: 30px;
  font-size: 20px;
  color: #B3B3B3;
}

.radio-inline input[type="radio"] {
  margin-top: 1px;
}

.logo-hold {
  padding-top: 45px;
}

.turbolinks-progress-bar {
  height: 5px;
  background-color: $primary-color;
}

.left-col-fixer {
  height: 408px;
}

.right-col-fixer {
  height: 408px;
}

.icon-cogholder-red {
  color: $brand-danger !important;
  font-size: 22px !important;
}

.bottom-spacer {
  margin-bottom: 15px;
}

form.narrow-form {
  width: 0px;
}

.modal-body textarea.form-control {
  height: 90px !important;
}

a.open-tender {
  color: $primary-color;
}

.btn-width-cancel {
  width: auto !important;
}

.btn-remove {
  background-color: transparent;
  cursor: pointer;
  display: block;
  color: #666666;
  border-bottom: 1px dotted #ccc;
  padding-bottom: 5px;
  margin-bottom: 5px;
  font-size: 13px;
  border-left: none;
  border-top: none;
  border-right: none;
  width: 100%;
  text-align: left;
  padding-left: 0;
}

.btn-remove:hover {
  color: #000;
  text-decoration: none;
}

.g-border {
  min-height: 75px;
  display: inline-block;
  width: 100%;
}

.open > .btn-primary.dropdown-toggle {
  margin-bottom: 0;
}

.g-border .dropdown-menu {
  border-radius: 0px;
  padding: 10px 10px;
  border: none ;
}

.dropdown .btn-spacer{
  margin: 5px 0;
}

//.dropdown .btn-primary{
//  border-radius: 0px ;
//}

.open  > .btn-primary:active:hover, .btn-primary:active:focus {
  background-color: $hoverbtn;
  border-color: $hoverbtn;
}

.open > .btn-primary.dropdown-toggle {
  background-color: $hoverbtn !important;
  border-color: $hoverbtn !important;
}

.btn-butt-spacer {
  margin-top: 20px;
}